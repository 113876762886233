var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "kelas-new-form pb-6 w-full min-h-contain"
  }, [_c('div', {
    staticClass: "mb-6 flex items-center"
  }, [_c('button', {
    staticClass: "mr-3 w-8 h-8 flex justify-center items-center focus:outline-none",
    on: {
      "click": function click($event) {
        return _vm.toClassListMenu();
      }
    }
  }, [_c('ArrowForward', {
    attrs: {
      "direction": "left"
    }
  })], 1), _c('h1', {
    staticClass: "text-3xl font-bold"
  }, [_vm._v(_vm._s(_vm.$route.meta.mode) + " Class")])]), _vm.errorPromoPrice === true ? _c('div', {
    staticClass: "absolute h-screen justify-center items-center justify-items-center ml-64 -mt-16"
  }, [_c('div', {
    staticClass: "border-2 border-red-indicator rounded-xl w-464 h-20 text-red-indicator bg-red-10 flex"
  }, [_c('div', {
    staticClass: "m-auto w-24"
  }, [_c('AlertCircle', {
    staticClass: "m-auto",
    attrs: {
      "width": "26",
      "height": "26"
    }
  })], 1), _vm._m(0)])]) : _vm._e(), _c('div', {
    staticClass: "tab flex justify-between items-end pl-4"
  }, [_c('div', {
    staticClass: "flex gap-2"
  }, [_c('div', {
    staticClass: "tab__item text-sm px-5 py-2 rounded-tr-lg rounded-tl-lg cursor-pointer",
    class: [_vm.selectedTab === 'General' ? 'font-bold bg-white' : 'bg-neutral-50'],
    on: {
      "click": function click($event) {
        return _vm.selectTab('General');
      }
    }
  }, [_vm._v(" General ")]), _c('div', {
    staticClass: "tab__item text-sm px-5 py-2 rounded-tr-lg rounded-tl-lg cursor-pointer",
    class: [_vm.selectedTab === 'Details' ? 'font-bold bg-white' : 'bg-neutral-50'],
    on: {
      "click": function click($event) {
        return _vm.selectTab('Details');
      }
    }
  }, [_vm._v(" Web Content ")]), _c('div', {
    staticClass: "tab__item text-sm px-5 py-2 rounded-tr-lg rounded-tl-lg cursor-pointer",
    class: [_vm.selectedTab === 'Certificate' ? 'font-bold bg-white' : 'bg-neutral-50'],
    on: {
      "click": function click($event) {
        return _vm.selectTab('Certificate');
      }
    }
  }, [_vm._v(" Certificate ")]), _c('div', {
    staticClass: "tab__item text-sm px-5 py-2 rounded-tr-lg rounded-tl-lg cursor-pointer",
    class: [_vm.selectedTab === 'Activities' ? 'font-bold bg-white' : 'bg-neutral-50'],
    on: {
      "click": function click($event) {
        return _vm.selectTab('Activities');
      }
    }
  }, [_vm._v(" Activities Template ")]), _vm.isEditMode ? _c('div', {
    staticClass: "tab__item text-sm px-5 py-2 rounded-tr-lg rounded-tl-lg cursor-pointer",
    class: [_vm.selectedTab === 'Schedule' ? 'font-bold bg-white' : 'bg-neutral-50'],
    on: {
      "click": function click($event) {
        return _vm.selectTab('Schedule');
      }
    }
  }, [_vm._v(" Class Schedule ")]) : _vm._e(), _c('div', {
    staticClass: "tab__item text-sm px-5 py-2 rounded-tr-lg rounded-tl-lg cursor-pointer",
    class: [_vm.selectedTab === 'Settings' ? 'font-bold bg-white' : 'bg-neutral-50'],
    on: {
      "click": function click($event) {
        return _vm.selectTab('Settings');
      }
    }
  }, [_vm._v(" Settings ")])]), _c('div', {
    staticClass: "flex justify-end py-2"
  }, [_c('Button', {
    attrs: {
      "disabled": _vm.disableSubmit || _vm.temporaryDisabled,
      "buttonText": _vm.isEditMode ? 'Update' : 'Submit',
      "size": "compact"
    },
    on: {
      "action": function action($event) {
        return _vm.save();
      }
    }
  })], 1)]), _c('div', {
    staticClass: "shadow-small rounded-lg bg-white"
  }, [_vm.selectedTab === 'General' ? [_c('div', {
    staticClass: "grid grid-cols-2"
  }, [_c('div', {
    staticClass: "p-6 flex-1 shadow-border-r flex-shrink-0"
  }, [_c('p', {
    staticClass: "font-semibold mb-4 flex gap-1 items-center border-b border-neutral-50 py-1"
  }, [_c('span', {
    staticClass: "-ml-1"
  }, [_c('Label', {
    attrs: {
      "color": "yellow"
    }
  })], 1), _vm._v("General ")]), _c('div', {
    staticClass: "mb-6"
  }, [_c('div', {
    staticClass: "form"
  }, [_vm.isEditMode ? _c('div', {
    staticClass: "mb-6"
  }, [_c('TextField', {
    attrs: {
      "type": "text",
      "label": "Id",
      "borderEnabled": true,
      "error": _vm.error['id'],
      "disabled": ""
    },
    model: {
      value: _vm.id,
      callback: function callback($$v) {
        _vm.id = $$v;
      },
      expression: "id"
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "mb-6"
  }, [_c('label', {
    staticClass: "text-neutral-500 text-xs font-medium mb-1 pl-3 block"
  }, [_vm._v("Program")]), _c('Dropdown', {
    attrs: {
      "options": _vm.programOptions,
      "optionLabel": "name",
      "default": _vm.program,
      "placeholder": "Select Program",
      "disabled": _vm.isEditMode
    },
    model: {
      value: _vm.program,
      callback: function callback($$v) {
        _vm.program = $$v;
      },
      expression: "program"
    }
  })], 1), _c('div', {
    staticClass: "mb-6"
  }, [_c('TextField', {
    attrs: {
      "type": "text",
      "isLabelRequire": "",
      "label": "Name",
      "placeholder": "Enter class name",
      "borderEnabled": true,
      "error": _vm.error['name'],
      "required": ""
    },
    model: {
      value: _vm.name,
      callback: function callback($$v) {
        _vm.name = $$v;
      },
      expression: "name"
    }
  })], 1), _c('div', {
    staticClass: "mb-6 flex gap-6"
  }, [_c('div', {
    staticClass: "flex-1"
  }, [_c('label', {
    staticClass: "text-neutral-500 text-xs font-medium pl-3 block mb-1"
  }, [_vm._v("Type")]), _c('div', {
    staticClass: "border border-grey-field p-4 rounded-lg"
  }, [_c('div', {
    staticClass: "grid grid-layout2"
  }, [_c('div', {
    staticClass: "leading-0"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.types,
      expression: "types"
    }],
    staticClass: "styled-checkbox",
    attrs: {
      "type": "checkbox",
      "id": "webinar",
      "value": "WEBINAR"
    },
    domProps: {
      "checked": Array.isArray(_vm.types) ? _vm._i(_vm.types, "WEBINAR") > -1 : _vm.types
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.types,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = "WEBINAR",
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && (_vm.types = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.types = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.types = $$c;
        }
      }
    }
  }), _c('label', {
    attrs: {
      "for": "webinar"
    }
  }, [_vm._v("Webinar")])]), _c('div', {
    staticClass: "leading-0"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.types,
      expression: "types"
    }],
    staticClass: "styled-checkbox",
    attrs: {
      "type": "checkbox",
      "id": "self-based",
      "value": "SELF_BASED"
    },
    domProps: {
      "checked": Array.isArray(_vm.types) ? _vm._i(_vm.types, "SELF_BASED") > -1 : _vm.types
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.types,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = "SELF_BASED",
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && (_vm.types = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.types = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.types = $$c;
        }
      }
    }
  }), _c('label', {
    attrs: {
      "for": "self-based"
    }
  }, [_vm._v("Self-based")])]), _c('div', {
    staticClass: "leading-0"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.types,
      expression: "types"
    }],
    staticClass: "styled-checkbox",
    attrs: {
      "type": "checkbox",
      "id": "offline",
      "value": "OFFLINE"
    },
    domProps: {
      "checked": Array.isArray(_vm.types) ? _vm._i(_vm.types, "OFFLINE") > -1 : _vm.types
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.types,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = "OFFLINE",
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && (_vm.types = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.types = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.types = $$c;
        }
      }
    }
  }), _c('label', {
    attrs: {
      "for": "offline"
    }
  }, [_vm._v("Offline")])]), _c('div', {
    staticClass: "leading-0"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.types,
      expression: "types"
    }],
    staticClass: "styled-checkbox",
    attrs: {
      "type": "checkbox",
      "id": "blended",
      "value": "BLENDED"
    },
    domProps: {
      "checked": Array.isArray(_vm.types) ? _vm._i(_vm.types, "BLENDED") > -1 : _vm.types
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.types,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = "BLENDED",
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && (_vm.types = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.types = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.types = $$c;
        }
      }
    }
  }), _c('label', {
    attrs: {
      "for": "blended"
    }
  }, [_vm._v("Blended")])])])])]), _c('div', {
    staticClass: "flex-1"
  }, [_c('TextField', {
    attrs: {
      "type": "text",
      "label": "Code",
      "placeholder": "Enter class code",
      "borderEnabled": true,
      "error": _vm.error['code'],
      "required": ""
    },
    model: {
      value: _vm.code,
      callback: function callback($$v) {
        _vm.code = $$v;
      },
      expression: "code"
    }
  })], 1)]), _c('div', {
    staticClass: "mb-10 flex gap-6"
  }, [_c('div', {
    staticClass: "flex-1"
  }, [_c('TextField', {
    attrs: {
      "type": "number",
      "min": "0",
      "label": "Min Participants",
      "borderEnabled": true,
      "error": _vm.error['minParticipants'],
      "required": ""
    },
    model: {
      value: _vm.minParticipants,
      callback: function callback($$v) {
        _vm.minParticipants = $$v;
      },
      expression: "minParticipants"
    }
  })], 1), _c('div', {
    staticClass: "flex-1"
  }, [_c('TextField', {
    attrs: {
      "type": "number",
      "min": "0",
      "isLabelRequire": "",
      "label": "Max Participants",
      "borderEnabled": true,
      "error": _vm.error['maxParticipants'],
      "required": ""
    },
    model: {
      value: _vm.maxParticipants,
      callback: function callback($$v) {
        _vm.maxParticipants = $$v;
      },
      expression: "maxParticipants"
    }
  })], 1)]), _c('div', {}, [_c('p', {
    staticClass: "font-semibold mb-4 flex gap-1 items-center border-b border-neutral-50 py-1"
  }, [_c('span', {
    staticClass: "-ml-1"
  }, [_c('Label', {
    attrs: {
      "color": "yellow"
    }
  })], 1), _vm._v("Instructor"), _c('span', {
    staticClass: "text-red"
  }, [_vm._v(" *")])]), _c('div', {
    staticClass: "mb-6"
  }, [_vm.coreClassInstructors.length > 0 ? _c('div', {
    staticClass: "p-4 bg-neutral-100 rounded-lg grid grid-cols-2 gap-5 mb-4"
  }, _vm._l(_vm.coreClassInstructors, function (instructor, instructorIdx) {
    return _c('div', {
      key: "coreClassInstructors" + instructorIdx
    }, [_c('p', {
      staticClass: "text-xs pl-3 font-medium mb-1 text-neutral-500"
    }, [_vm._v("Instructor " + _vm._s(instructorIdx + 1))]), _c('div', {
      staticClass: "flex gap-3"
    }, [_c('Dropdown', {
      staticClass: "w-full",
      attrs: {
        "options": _vm.instructorOptions,
        "default": _vm.coreClassInstructors[instructorIdx],
        "placeholder": "Select Instructor",
        "optionLabel": "name",
        "indexData": instructorIdx,
        "maxHeight": "200px",
        "enableSearch": ""
      },
      on: {
        "search": _vm.getInstructorList
      },
      model: {
        value: _vm.coreClassInstructors[instructorIdx],
        callback: function callback($$v) {
          _vm.$set(_vm.coreClassInstructors, instructorIdx, $$v);
        },
        expression: "coreClassInstructors[instructorIdx]"
      }
    }), _c('div', {
      staticClass: "flex items-center"
    }, [_c('Button', {
      attrs: {
        "type": "secondary",
        "size": "icon",
        "icon": function icon() {
          return import('@/components/Icons/Trash');
        }
      },
      on: {
        "action": function action($event) {
          return _vm.deleteInstructor(instructorIdx);
        }
      }
    })], 1)], 1)]);
  }), 0) : _vm._e(), _c('div', {
    staticClass: "flex"
  }, [_c('Button', {
    attrs: {
      "buttonText": _vm.coreClassInstructors.length > 0 ? 'Add More Instructor' : 'Add Instructor',
      "size": "compact",
      "type": "tertiary"
    },
    on: {
      "action": _vm.addInstructor
    }
  })], 1)])])])])]), _c('div', {
    staticClass: "p-6 flex-1 flex-shrink-0"
  }, [_c('p', {
    staticClass: "font-semibold mb-4 flex gap-1 items-center border-b border-neutral-50 py-1"
  }, [_c('span', {
    staticClass: "-ml-1"
  }, [_c('Label', {
    attrs: {
      "color": "yellow"
    }
  })], 1), _vm._v("Excercise ")]), _c('div', {
    staticClass: "mb-10"
  }, [_c('label', {
    staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block mb-1"
  }, [_vm._v("Description")]), _c('quill-editor', {
    attrs: {
      "options": _vm.quillOptions
    },
    model: {
      value: _vm.description,
      callback: function callback($$v) {
        _vm.description = $$v;
      },
      expression: "description"
    }
  })], 1), _c('p', {
    staticClass: "font-semibold mb-4 flex gap-1 items-center border-b border-neutral-50 py-1"
  }, [_c('span', {
    staticClass: "-ml-1"
  }, [_c('Label', {
    attrs: {
      "color": "yellow"
    }
  })], 1), _vm._v("Quiz ")]), _c('div', {
    staticClass: "mb-10"
  }, [_vm.quizes.length > 0 ? _c('div', {
    staticClass: "mt-3 mb-3 p-4 bg-neutral-100 rounded-lg grid grid-layout"
  }, _vm._l(_vm.quizes, function (item, index) {
    return _c('div', {
      key: 'quiz -' + index
    }, [_c('div', {
      staticClass: "flex justify-between items-center"
    }, [_c('p', {
      staticClass: "text-xs pl-3 font-medium mb-1 text-neutral-500"
    }, [_vm._v("Quiz " + _vm._s(index + 1))])]), _c('div', {
      staticClass: "flex gap-2"
    }, [_c('select', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.quizes[index],
        expression: "quizes[index]"
      }],
      staticClass: "w-full pl-2 h-11 rounded-lg border-grey-field border outline-none text-sm text-neutral-500",
      on: {
        "change": function change($event) {
          var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
            return o.selected;
          }).map(function (o) {
            var val = "_value" in o ? o._value : o.value;
            return val;
          });

          _vm.$set(_vm.quizes, index, $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
        }
      }
    }, [_c('option', {
      attrs: {
        "value": "",
        "disabled": "",
        "selected": ""
      }
    }, [_vm._v("Select quiz")]), _vm._l(_vm.listQuiz, function (item) {
      return _c('option', {
        key: item.id,
        domProps: {
          "value": item.id
        }
      }, [_vm._v(_vm._s(item.name))]);
    })], 2), _c('Button', {
      attrs: {
        "type": "secondary",
        "size": "icon",
        "icon": function icon() {
          return import(
          /* webpackChunkName: 'icon' */
          '@/components/Icons/Trash');
        }
      },
      on: {
        "action": function action($event) {
          return _vm.deleteQuiz(index);
        }
      }
    })], 1)]);
  }), 0) : _vm._e(), _c('div', {
    staticClass: "flex"
  }, [_c('Button', {
    attrs: {
      "buttonText": _vm.quizes.length > 0 ? 'Add More Quiz' : 'Add Quiz',
      "type": "tertiary",
      "size": "compact"
    },
    on: {
      "action": _vm.addQuiz
    }
  })], 1)]), _c('p', {
    staticClass: "font-semibold mb-4 flex gap-1 items-center border-b border-neutral-50 py-1"
  }, [_c('span', {
    staticClass: "-ml-1"
  }, [_c('Label', {
    attrs: {
      "color": "yellow"
    }
  })], 1), _vm._v("Enrollment Condition ")]), _c('div', {
    staticClass: "mb-6"
  }, [_c('div', {
    staticClass: "mb-3"
  }, _vm._l(_vm.conditions, function (condition, index) {
    return _c('div', {
      key: 'condition-' + index
    }, [_c('div', {
      staticClass: "flex justify-between items-end mt-4 mb-2"
    }, [_c('p', {
      staticClass: "text-sm font-medium mb-1 text-neutral-500"
    }, [_vm._v("Class Enrollment Condition " + _vm._s(index + 1))]), _c('Button', {
      attrs: {
        "type": "secondary",
        "size": "icon",
        "icon": function icon() {
          return import(
          /* webpackChunkName: 'icon' */
          '@/components/Icons/Trash');
        }
      },
      on: {
        "action": function action($event) {
          return _vm.deleteCondition(index);
        }
      }
    })], 1), _c('div', {
      staticClass: "p-4 bg-neutral-100 rounded-lg"
    }, [_vm._l(condition.or, function (child, childIndex) {
      return _c('div', {
        key: 'child-' + childIndex
      }, [_c('div', {
        staticClass: "flex justify-between items-center mb-2"
      }, [_c('p', {
        staticClass: "text-sm font-medium mb-2 text-neutral-500"
      }, [_vm._v(" Enrollment Condition " + _vm._s(childIndex + 1) + " - " + _vm._s(_vm.withCondition(index, childIndex) === 'userHasPassed' ? 'User has passed' : 'User has completed') + " ")]), _c('Button', {
        attrs: {
          "type": "secondary",
          "size": "icon",
          "icon": function icon() {
            return import('@/components/Icons/Trash');
          }
        },
        on: {
          "action": function action($event) {
            return _vm.deleteChildCondition(index, childIndex);
          }
        }
      })], 1), _c('div', {
        staticClass: "bg-neutral-100 p-4 rounded-lg mb-6"
      }, [_c('div', {
        staticClass: "mb-4"
      }, [_c('label', {
        staticClass: "text-neutral-500 text-xs font-medium mb-1 pl-3 block"
      }, [_vm._v("Class")]), _c('div', [_c('select', {
        directives: [{
          name: "model",
          rawName: "v-model",
          value: _vm.forVModel(index, childIndex).coreClassId,
          expression: "forVModel(index, childIndex).coreClassId"
        }],
        staticClass: "w-full pl-2 h-11 rounded-lg border-grey-field border outline-none text-sm text-neutral-500",
        on: {
          "change": function change($event) {
            var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
              return o.selected;
            }).map(function (o) {
              var val = "_value" in o ? o._value : o.value;
              return val;
            });

            _vm.$set(_vm.forVModel(index, childIndex), "coreClassId", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
          }
        }
      }, [_c('option', {
        attrs: {
          "value": "",
          "disabled": "",
          "selected": ""
        }
      }, [_vm._v("Select class")]), _vm._l(_vm.listClass, function (item, index) {
        return _c('option', {
          key: 'cciWc' + index,
          domProps: {
            "value": item.id
          }
        }, [_vm._v(_vm._s(item.nama))]);
      })], 2), _c('div', {
        staticClass: "my-3"
      }, [_c('input', {
        staticClass: "styled-checkbox",
        attrs: {
          "type": "checkbox",
          "id": "wc-".concat(index, "-").concat(childIndex)
        },
        domProps: {
          "checked": _vm.isChecked(index, childIndex)
        },
        on: {
          "click": function click($event) {
            return _vm.addWithinTime(index, childIndex);
          }
        }
      }), _c('label', {
        staticStyle: {
          "font-size": "0.8rem"
        },
        attrs: {
          "for": "wc-".concat(index, "-").concat(childIndex)
        }
      }, [_vm._v("Add Time Constraint condition")])]), _c('div', {
        directives: [{
          name: "show",
          rawName: "v-show",
          value: _vm.forVModel(index, childIndex).withinTime,
          expression: "forVModel(index, childIndex).withinTime"
        }],
        staticClass: "flex justify-between gap-6"
      }, [_c('div', {
        staticClass: "w-1/2"
      }, [_c('label', {
        staticClass: "text-neutral-500 text-xs font-medium mb-1 pl-3 block"
      }, [_vm._v("Time Value")]), _c('input', {
        staticClass: "w-full form-control m-input h-11 pl-3 rounded-lg border border-grey-field outline-none",
        attrs: {
          "placeholder": "Input Number",
          "type": "number"
        },
        domProps: {
          "value": _vm.forVModel(index, childIndex).withinTime ? _vm.forVModel(index, childIndex).withinTime.value : ''
        },
        on: {
          "input": function input($event) {
            _vm.forVModel(index, childIndex).withinTime.value = $event.target.value;
          }
        }
      })]), _c('div', {
        staticClass: "w-1/2"
      }, [_c('label', {
        staticClass: "text-neutral-500 text-xs font-medium mb-1 pl-3 block"
      }, [_vm._v("Time Parameter")]), _c('select', {
        staticClass: "w-full pl-2 h-11 rounded-lg border-grey-field border outline-none text-sm text-neutral-500",
        domProps: {
          "value": _vm.forVModel(index, childIndex).withinTime ? _vm.forVModel(index, childIndex).withinTime.type : ''
        },
        on: {
          "input": function input($event) {
            _vm.forVModel(index, childIndex).withinTime.type = $event.target.value;
          }
        }
      }, _vm._l(_vm.listTimeParameter, function (item, index) {
        return _c('option', {
          key: 'tpWc-' + index,
          domProps: {
            "value": item.id
          }
        }, [_vm._v(_vm._s(item.name))]);
      }), 0)])])])])])]);
    }), _c('div', {
      staticClass: "flex justify-around h-8"
    }, [_c('Button', {
      attrs: {
        "buttonText": "New User Has Passed",
        "size": "small",
        "type": "secondary"
      },
      on: {
        "action": function action($event) {
          return _vm.addChildConditionUHP(index);
        }
      }
    }), _c('Button', {
      attrs: {
        "buttonText": "New User Has Completed",
        "size": "small",
        "type": "secondary"
      },
      on: {
        "action": function action($event) {
          return _vm.addChildConditionUHC(index);
        }
      }
    })], 1)], 2)]);
  }), 0), _c('div', {
    staticClass: "flex"
  }, [_c('Button', {
    attrs: {
      "buttonText": "Add Condition",
      "size": "compact",
      "type": "tertiary"
    },
    on: {
      "action": function action($event) {
        return _vm.addCondition();
      }
    }
  })], 1)]), _vm.isEditMode && _vm.programSelected === 'Bootcamp' ? _c('div', [_c('p', {
    staticClass: "font-semibold mb-4 flex gap-1 items-center border-b border-neutral-50 py-1"
  }, [_c('span', {
    staticClass: "-ml-1"
  }, [_c('Label', {
    attrs: {
      "color": "yellow"
    }
  })], 1), _vm._v("Class Variant ")]), _vm.classVariants.length > 0 ? _c('div', {
    staticClass: "p-4 bg-neutral-100 rounded-lg grid grid-cols-1 gap-5 mb-4"
  }, _vm._l(_vm.classVariants, function (variant, variantIdx) {
    return _c('div', {
      key: 'classVariants' + variantIdx,
      staticClass: "flex gap-3 items-center"
    }, [_c('Dropdown', {
      staticClass: "w-full",
      attrs: {
        "label": "Attribute ".concat(variantIdx + 1),
        "options": _vm.classAttributeOptions,
        "default": _vm.classVariants[variantIdx],
        "placeholder": "Choose available attribute",
        "optionLabel": "attributeName",
        "maxHeight": "200px"
      },
      on: {
        "onChangeSelected": _vm.handleClassVariantChange
      },
      model: {
        value: _vm.classVariants[variantIdx],
        callback: function callback($$v) {
          _vm.$set(_vm.classVariants, variantIdx, $$v);
        },
        expression: "classVariants[variantIdx]"
      }
    }), _c('div', {
      staticClass: "pt-5"
    }, [_c('Button', {
      attrs: {
        "type": "secondary",
        "size": "icon",
        "icon": function icon() {
          return import('@/components/Icons/Trash');
        }
      },
      on: {
        "action": function action($event) {
          return _vm.deleteVariant(_vm.classVariants, variantIdx);
        }
      }
    })], 1)], 1);
  }), 0) : _vm._e(), _c('Button', {
    attrs: {
      "buttonText": "Add Attribute",
      "size": "compact",
      "type": "tertiary",
      "disabled": _vm.classVariants.length >= 3
    },
    on: {
      "action": function action($event) {
        return _vm.addNewVariant();
      }
    }
  }), _vm.classVariantDataTable.length > 0 ? _c('div', {
    staticClass: "mt-4"
  }, [_c('TableComponent', {
    attrs: {
      "columns": _vm.columnsTableClassVariant,
      "list": _vm.classVariantDataTable,
      "compact": ""
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(_ref) {
        var entry = _ref.entry,
            column = _ref.column;
        return [column.label === 'Actions' ? _c('div', {
          staticClass: "flex justify-end gap-3 mr-4"
        }, [_c('Button', {
          attrs: {
            "type": "tertiary",
            "size": "icon",
            "forIcon": {
              color: 'yellow'
            },
            "icon": function icon() {
              return import(
              /* webpackChunkName: 'icon' */
              '@/components/Icons/Pencil');
            },
            "tooltip": {
              show: true,
              text: 'Edit'
            }
          },
          on: {
            "action": function action($event) {
              return _vm.toggleClassVariantModal(entry);
            }
          }
        })], 1) : column.label === 'Price' || column.label === 'Promo' ? _c('div', {
          staticClass: "flex items-center gap-1"
        }, [_c('p', [_vm._v("Rp.")]), _c('TextField', {
          staticClass: "w-28",
          attrs: {
            "type": "number",
            "borderEnabled": "",
            "placeholder": "0"
          },
          model: {
            value: entry[column.field],
            callback: function callback($$v) {
              _vm.$set(entry, column.field, $$v);
            },
            expression: "entry[column.field]"
          }
        })], 1) : column.label === 'Status' ? _c('div', [_vm._v(_vm._s(entry[column.field] ? 'Active' : 'Inactive'))]) : _c('div', [_vm._v(_vm._s(entry[column.field]))])];
      }
    }], null, false, 3367255758)
  })], 1) : _vm._e()], 1) : _vm._e()])])] : _vm._e(), _vm.selectedTab === 'Details' ? [_c('WebContent', {
    attrs: {
      "payload": _vm.payload,
      "programSelected": _vm.programSelected,
      "scheduledPrices": _vm.scheduledPrices,
      "historyAlumniClasses": _vm.historyAlumniClasses,
      "testimonyList": _vm.testimonyList,
      "coreClassPaymentMethod": _vm.coreClassPaymentMethod,
      "paymentMethodSelected": _vm.paymentMethodSelected,
      "syllabusDocument": _vm.syllabusDocument,
      "coreClassSoftwareTool": _vm.coreClassSoftwareTool
    },
    on: {
      "handlePaymentMethodSelected": _vm.handlePaymentMethodSelected,
      "handleSyllabusDocumentMutate": _vm.handleSyllabusDocumentMutate,
      "addMoreItem": _vm.addMoreItem,
      "deleteItem": _vm.deleteItem
    }
  })] : _vm._e(), _vm.selectedTab === 'Certificate' ? [_c('div', {
    staticClass: "w-full p-6"
  }, [_c('div', {
    staticClass: "mb-6 mt-2"
  }, [_c('p', {
    staticClass: "font-semibold mb-4 flex gap-1 items-center border-b border-neutral-50 py-1"
  }, [_c('span', {
    staticClass: "-ml-1"
  }, [_c('Label', {
    attrs: {
      "color": "yellow"
    }
  })], 1), _vm._v("Certificate Competence ")]), _vm.certificateCompetence.length > 0 ? _c('div', {
    staticClass: "p-4 rounded-lg mb-4 border-2 w-max"
  }, [_vm._l(_vm.certificateCompetence, function (item, idx) {
    return _c('div', {
      key: idx,
      staticClass: "mb-5"
    }, [_c('p', {
      staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block mb-1"
    }, [_vm._v("Competence " + _vm._s(idx + 1))]), _c('div', {
      staticClass: "flex gap-2"
    }, [_c('TextField', {
      staticClass: "w-325",
      attrs: {
        "type": "text",
        "borderEnabled": "",
        "placeholder": "placeholder"
      },
      model: {
        value: _vm.certificateCompetence[idx],
        callback: function callback($$v) {
          _vm.$set(_vm.certificateCompetence, idx, $$v);
        },
        expression: "certificateCompetence[idx]"
      }
    }), _c('Button', {
      attrs: {
        "type": "secondary",
        "size": "icon",
        "icon": function icon() {
          return import(
          /* webpackChunkName: 'icon' */
          '@/components/Icons/Trash');
        }
      },
      on: {
        "action": function action($event) {
          return _vm.deleteItem(_vm.certificateCompetence, idx);
        }
      }
    })], 1)]);
  }), _vm.certificateCompetence.length > 0 ? _c('div', [_c('Button', {
    attrs: {
      "buttonText": _vm.certificateCompetence.length > 0 ? 'Add Competence' : 'Add Competence',
      "size": "compact",
      "type": "tertiary",
      "icon": function icon() {
        return import(
        /* webpackChunkName: 'icon' */
        '@/components/Icons/Plus');
      }
    },
    on: {
      "action": function action($event) {
        return _vm.addMoreItem(_vm.certificateCompetence, 'certifCompetenceId');
      }
    }
  })], 1) : _vm._e()], 2) : _c('div', {
    staticClass: "flex ml-4"
  }, [_c('Button', {
    attrs: {
      "buttonText": _vm.certificateCompetence.length > 0 ? 'Add Competence' : 'Add Competence',
      "size": "compact",
      "type": "tertiary",
      "icon": function icon() {
        return import(
        /* webpackChunkName: 'icon' */
        '@/components/Icons/Plus');
      }
    },
    on: {
      "action": function action($event) {
        return _vm.addMoreItem(_vm.certificateCompetence, 'certifCompetenceId');
      }
    }
  })], 1)]), _c('div', {
    staticClass: "mb-6 w-2/3"
  }, [_c('p', {
    staticClass: "font-semibold mb-4 flex gap-1 items-center border-b border-neutral-50 py-1"
  }, [_c('span', {
    staticClass: "-ml-1"
  }, [_c('Label', {
    attrs: {
      "color": "yellow"
    }
  })], 1), _vm._v("Certificate Type ")]), _vm.certificates.length > 0 ? _c('div', {
    staticClass: "border-black-200 p-5 border-2 mb-6"
  }, [_vm._l(_vm.certificates, function (item, idx) {
    return _c('div', {
      key: idx,
      staticClass: "mb-6"
    }, [_c('div', {
      staticClass: "flex justify-between pb-2"
    }, [_c('span', {
      staticClass: "text-sm py-1 block"
    }, [_vm._v("Certificate " + _vm._s(idx + 1))]), _c('div', {
      staticClass: "bg-red-100 px-1 ml-2 rounded-lg cursor-pointer"
    }, [_c('Button', {
      attrs: {
        "type": "secondary",
        "size": "icon",
        "icon": function icon() {
          return import(
          /* webpackChunkName: 'icon' */
          '@/components/Icons/Trash');
        }
      },
      on: {
        "action": function action($event) {
          return _vm.deleteItem(_vm.certificates, idx);
        }
      }
    })], 1)]), _c('div', {
      staticClass: "border-2 bg-neutral-100 p-4"
    }, [_c('div', {
      staticClass: "mb-6 w-2/3"
    }, [_c('TextField', {
      attrs: {
        "type": "text",
        "label": "Title",
        "borderEnabled": "",
        "placeholder": "Enter title"
      },
      model: {
        value: item.title,
        callback: function callback($$v) {
          _vm.$set(item, "title", $$v);
        },
        expression: "item.title"
      }
    })], 1), _c('div', {
      staticClass: "mb-6 w-2/3"
    }, [_c('label', {
      staticClass: "text-neutral-500 text-xs font-medium mb-1 pl-3"
    }, [_vm._v("Type")]), _c('Dropdown', {
      attrs: {
        "options": _vm.certificateTypeOptions,
        "optionLabel": "name",
        "default": _vm.certificates[idx].certificateType,
        "placeholder": "Select"
      },
      on: {
        "change": function change(opt) {
          return _vm.certificates[idx].certificate_type = opt.code;
        }
      },
      model: {
        value: _vm.certificates[idx].certificateType,
        callback: function callback($$v) {
          _vm.$set(_vm.certificates[idx], "certificateType", $$v);
        },
        expression: "certificates[idx].certificateType"
      }
    })], 1), _c('div', {
      staticClass: "mb-6 w-2/3"
    }, [_c('label', {
      staticClass: "text-neutral-500 text-xs font-medium mb-1 pl-3"
    }, [_vm._v("Description")]), _c('textarea', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: item.description,
        expression: "item.description"
      }],
      staticClass: "h-52 text-sm border-grey-field border resize-none px-3 py-3 focus:outline-none focus:ring focus:ring-white w-full rounded-lg",
      attrs: {
        "label": "Description"
      },
      domProps: {
        "value": item.description
      },
      on: {
        "input": function input($event) {
          if ($event.target.composing) { return; }

          _vm.$set(item, "description", $event.target.value);
        }
      }
    })]), _c('div', {
      staticClass: "w-2/3"
    }, [_c('label', {
      staticClass: "text-neutral-500 text-xs font-medium mb-1 pl-3"
    }, [_vm._v("Template")]), _c('Dropdown', {
      attrs: {
        "options": _vm.certificateTemplateOptions,
        "optionLabel": "name",
        "default": _vm.certificates[idx].certificateTemplate,
        "placeholder": "Select"
      },
      on: {
        "change": function change(opt) {
          _vm.certificates[idx].certificate_template_id = opt.id;
          _vm.certificates[idx].certificateId = opt.id;
        }
      },
      model: {
        value: _vm.certificates[idx].certificateTemplate,
        callback: function callback($$v) {
          _vm.$set(_vm.certificates[idx], "certificateTemplate", $$v);
        },
        expression: "certificates[idx].certificateTemplate"
      }
    })], 1)])]);
  }), _vm.certificates.length > 0 ? _c('div', {
    staticClass: "flex justify-center"
  }, [_c('Button', {
    attrs: {
      "buttonText": _vm.certificates.length > 0 ? 'Add New Certificate' : 'Add New Certificate',
      "size": "compact",
      "type": "tertiary"
    },
    on: {
      "action": function action($event) {
        return _vm.addMoreItem(_vm.certificates, 'certifTypeId');
      }
    }
  })], 1) : _vm._e()], 2) : _c('div', {
    staticClass: "flex justify-center"
  }, [_c('Button', {
    attrs: {
      "buttonText": _vm.certificates.length > 0 ? 'Add New Certificate' : 'Add New Certificate',
      "size": "compact",
      "type": "tertiary"
    },
    on: {
      "action": function action($event) {
        return _vm.addMoreItem(_vm.certificates, 'certifTypeId');
      }
    }
  })], 1)])])] : _vm._e(), _vm.selectedTab === 'Activities' ? [_c('div', {
    staticClass: "p-6"
  }, [_vm.activities.length === 0 ? _c('div', {
    staticClass: "text-center py-16"
  }, [_vm._m(1), _c('p', {
    staticClass: "font-bold text-neutral-500 mb-3"
  }, [_vm._v("This class doesn't have activity template")]), _c('Button', {
    attrs: {
      "buttonText": "Create New"
    },
    on: {
      "action": function action($event) {
        return _vm.addNewActivity();
      }
    }
  })], 1) : _c('div', {}, [_vm._l(_vm.activities, function (activity, index) {
    return _c('div', {
      key: 'activity-' + index
    }, [_c('ActivityForm', {
      key: _vm.refresh,
      ref: 'activityForm' + index,
      refInFor: true,
      attrs: {
        "isFirstIndex": index === 0,
        "isLastIndex": index === _vm.activities.length - 1,
        "data": activity,
        "index": index,
        "classId": _vm.selectedClass && _vm.selectedClass.id,
        "primaryInstructor": _vm.selectedInstructor,
        "isTemplateAct": "",
        "enableFirstAct": ""
      },
      on: {
        "changePosition": function changePosition($event) {
          var _vm2;

          return (_vm2 = _vm).changePosition.apply(_vm2, ['activity'].concat(Array.prototype.slice.call(arguments)));
        },
        "changePositionChild": function changePositionChild($event) {
          var _vm3;

          return (_vm3 = _vm).changePosition.apply(_vm3, ['child'].concat(Array.prototype.slice.call(arguments)));
        },
        "delete": function _delete($event) {
          var _vm4;

          return (_vm4 = _vm).openDeleteModal.apply(_vm4, ['activity'].concat(Array.prototype.slice.call(arguments)));
        },
        "deleteChild": function deleteChild($event) {
          var _vm5;

          return (_vm5 = _vm).openDeleteModal.apply(_vm5, ['child'].concat(Array.prototype.slice.call(arguments)));
        },
        "deleteInstructorActivityItem": _vm.deleteInstructorActivityItem
      }
    })], 1);
  }), _c('Button', {
    attrs: {
      "buttonText": "Add New Activity",
      "type": "secondary"
    },
    on: {
      "action": function action($event) {
        return _vm.addNewActivity();
      }
    }
  })], 2)])] : _vm._e(), _vm.selectedTab === 'Schedule' ? [_c('div', {
    staticClass: "w-full p-6"
  }, [_vm.list_jadwalkelas.length > 0 ? _c('div', [_c('TableComponent', {
    attrs: {
      "columns": _vm.columns,
      "list": _vm.list_jadwalkelas
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(_ref2) {
        var entry = _ref2.entry,
            column = _ref2.column;
        return [column.field === 'start_date' ? _c('div', [entry.start_date ? _c('span', [_c('span', {
          staticClass: "block whitespace-nowrap"
        }, [_vm._v(" " + _vm._s(_vm.formatDate(entry.start_date).date) + " ")]), _c('span', {
          staticClass: "block text-xs whitespace-nowrap font-normal"
        }, [_vm._v(" " + _vm._s(_vm.formatDate(entry.start_date).time) + " ")])]) : _c('span', [_vm._v("-")])]) : column.field === 'batch' ? _c('div', [_vm._v(" " + _vm._s(typeof entry.batch !== 'undefined' ? entry.batch : '-') + " ")]) : column.field === 'nama' ? _c('div', [_c('router-link', {
          staticClass: "text-yellow",
          attrs: {
            "to": {
              path: '/jadwalkelas',
              query: {
                id: entry.id
              }
            }
          }
        }, [_vm._v(_vm._s(entry.nama))])], 1) : _c('div', [_vm._v(_vm._s(entry[column.field]))])];
      }
    }], null, false, 928096908)
  }), _c('div', {
    staticClass: "mt-6 flex justify-center items-center"
  }, [_c('Pagination', {
    attrs: {
      "currentPage": _vm.currentSchedulePage,
      "totalCount": _vm.totalRecords,
      "pageSize": _vm.schedulePageSize
    },
    on: {
      "onPageChange": _vm.onPageChange
    }
  })], 1)], 1) : _c('div', {
    staticClass: "w-full h-60 border border-neutral-250 rounded-lg text-sm bg-white flex justify-center items-center"
  }, [_c('p', [_vm._v("No schedule available")])])])] : _vm._e(), _vm.selectedTab === 'Settings' ? [_c('div', {
    staticClass: "w-full p-6"
  }, [_c('div', {
    staticClass: "mb-6 mt-2"
  }, [_c('p', {
    staticClass: "font-semibold mb-4 flex gap-1 items-center border-b border-neutral-50 py-1"
  }, [_c('span', {
    staticClass: "-ml-1"
  }, [_c('Label', {
    attrs: {
      "color": "yellow"
    }
  })], 1), _vm._v("Settings ")]), _c('div', {
    staticClass: "pl-5"
  }, [_c('div', {
    staticClass: "leading-0 mb-2"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.archived,
      expression: "archived"
    }],
    staticClass: "styled-checkbox",
    attrs: {
      "type": "checkbox",
      "id": "archived"
    },
    domProps: {
      "checked": Array.isArray(_vm.archived) ? _vm._i(_vm.archived, null) > -1 : _vm.archived
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.archived,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && (_vm.archived = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.archived = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.archived = $$c;
        }
      }
    }
  }), _c('label', {
    attrs: {
      "for": "archived"
    }
  }, [_vm._v("Archived")])]), _c('div', {
    staticClass: "leading-0 mb-2"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.autoCreateLmsClass,
      expression: "autoCreateLmsClass"
    }],
    staticClass: "styled-checkbox",
    attrs: {
      "type": "checkbox",
      "id": "autoCreateLmsClass"
    },
    domProps: {
      "checked": Array.isArray(_vm.autoCreateLmsClass) ? _vm._i(_vm.autoCreateLmsClass, null) > -1 : _vm.autoCreateLmsClass
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.autoCreateLmsClass,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && (_vm.autoCreateLmsClass = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.autoCreateLmsClass = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.autoCreateLmsClass = $$c;
        }
      }
    }
  }), _c('label', {
    attrs: {
      "for": "autoCreateLmsClass"
    }
  }, [_vm._v("Auto create LMS class")])]), _c('div', {
    staticClass: "leading-0"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.requireAdministration,
      expression: "requireAdministration"
    }],
    staticClass: "styled-checkbox",
    attrs: {
      "type": "checkbox",
      "id": "requireAdministration"
    },
    domProps: {
      "checked": Array.isArray(_vm.requireAdministration) ? _vm._i(_vm.requireAdministration, null) > -1 : _vm.requireAdministration
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.requireAdministration,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && (_vm.requireAdministration = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.requireAdministration = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.requireAdministration = $$c;
        }
      }
    }
  }), _c('label', {
    attrs: {
      "for": "requireAdministration"
    }
  }, [_vm._v("Require administration")])]), _c('div', {
    staticClass: "leading-0 mt-2"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.informationOnly,
      expression: "informationOnly"
    }],
    staticClass: "styled-checkbox",
    attrs: {
      "type": "checkbox",
      "id": "informationOnly"
    },
    domProps: {
      "checked": Array.isArray(_vm.informationOnly) ? _vm._i(_vm.informationOnly, null) > -1 : _vm.informationOnly
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.informationOnly,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && (_vm.informationOnly = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.informationOnly = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.informationOnly = $$c;
        }
      }
    }
  }), _c('label', {
    attrs: {
      "for": "informationOnly"
    }
  }, [_vm._v("Information Only")])]), _c('div', {
    staticClass: "leading-0 mt-2"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.autoExpandActivity,
      expression: "autoExpandActivity"
    }],
    staticClass: "styled-checkbox",
    attrs: {
      "type": "checkbox",
      "id": "autoExpandActivity"
    },
    domProps: {
      "checked": Array.isArray(_vm.autoExpandActivity) ? _vm._i(_vm.autoExpandActivity, null) > -1 : _vm.autoExpandActivity
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.autoExpandActivity,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && (_vm.autoExpandActivity = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.autoExpandActivity = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.autoExpandActivity = $$c;
        }
      }
    }
  }), _c('label', {
    attrs: {
      "for": "autoExpandActivity"
    }
  }, [_vm._v("Auto-expand Activity in LMS Student")])]), _vm.programSelected === 'Prakerja' ? _c('div', {
    staticClass: "leading-0 mt-2"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.requirePmoRedeem,
      expression: "requirePmoRedeem"
    }],
    staticClass: "styled-checkbox",
    attrs: {
      "type": "checkbox",
      "id": "requirePmoRedeem"
    },
    domProps: {
      "checked": Array.isArray(_vm.requirePmoRedeem) ? _vm._i(_vm.requirePmoRedeem, null) > -1 : _vm.requirePmoRedeem
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.requirePmoRedeem,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && (_vm.requirePmoRedeem = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.requirePmoRedeem = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.requirePmoRedeem = $$c;
        }
      }
    }
  }), _c('label', {
    attrs: {
      "for": "requirePmoRedeem"
    }
  }, [_vm._v("Require PMO Redeem")])]) : _vm._e(), _vm.programSelected === 'Bootcamp' || _vm.programSelected === 'Short Course' ? _c('div', {
    staticClass: "leading-0 mt-2"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.requireCheckLmsClass,
      expression: "requireCheckLmsClass"
    }],
    staticClass: "styled-checkbox",
    attrs: {
      "type": "checkbox",
      "id": "requireCheckLmsClass"
    },
    domProps: {
      "checked": Array.isArray(_vm.requireCheckLmsClass) ? _vm._i(_vm.requireCheckLmsClass, null) > -1 : _vm.requireCheckLmsClass
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.requireCheckLmsClass,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && (_vm.requireCheckLmsClass = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.requireCheckLmsClass = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.requireCheckLmsClass = $$c;
        }
      }
    }
  }), _c('label', {
    attrs: {
      "for": "requireCheckLmsClass"
    }
  }, [_vm._v("Enable Waiting List feature")])]) : _vm._e(), _c('div', {
    staticClass: "leading-0 mt-2"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.requireMidtrans,
      expression: "requireMidtrans"
    }],
    staticClass: "styled-checkbox",
    attrs: {
      "type": "checkbox",
      "id": "requireMidtrans"
    },
    domProps: {
      "checked": Array.isArray(_vm.requireMidtrans) ? _vm._i(_vm.requireMidtrans, null) > -1 : _vm.requireMidtrans
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.requireMidtrans,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && (_vm.requireMidtrans = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.requireMidtrans = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.requireMidtrans = $$c;
        }
      }
    }
  }), _c('label', {
    attrs: {
      "for": "requireMidtrans"
    }
  }, [_vm._v("Auto-send Midtrans Invoice")])])])])])] : _vm._e()], 2), _c('Modal', {
    attrs: {
      "modalVisible": _vm.visibleDeleteModal,
      "width": "640px",
      "id": "delete"
    },
    on: {
      "close": function close($event) {
        return _vm.closeDeleteModal();
      }
    }
  }, [_c('template', {
    slot: "modal-content"
  }, [_c('div', {
    staticClass: "text-center my-6"
  }, [_c('p', {
    staticClass: "text-2xl font-bold mb-3"
  }, [_vm._v("Are you sure to delete this " + _vm._s(_vm.selectedTypeToBeDeleted) + "?")]), _c('p', {
    staticClass: "text-sm mb-4"
  }, [_vm._v("This action can not be undone")]), _c('div', {
    staticClass: "flex justify-center items-center gap-8"
  }, [_c('Button', {
    attrs: {
      "buttonText": "Delete",
      "type": "secondary"
    },
    on: {
      "action": function action($event) {
        return _vm.deleteActivityItem();
      }
    }
  }), _c('Button', {
    attrs: {
      "buttonText": "Cancel",
      "type": "primary"
    },
    on: {
      "action": function action($event) {
        return _vm.closeDeleteModal();
      }
    }
  })], 1)])])], 2), _c('Modal', {
    attrs: {
      "modalVisible": _vm.classVariantDetailVisible,
      "id": "class-variant-in-core-class"
    },
    on: {
      "close": function close($event) {
        return _vm.toggleClassVariantModal();
      }
    }
  }, [_c('template', {
    slot: "modal-content"
  }, [_c('div', {
    staticClass: "text-left mt-6"
  }, [_c('p', {
    staticClass: "text-2xl font-bold mb-3"
  }, [_vm._v("Edit Variant")]), _c('div', {
    staticClass: "border-b border-neutral-50 pb-4 mb-3 space-y-3"
  }, [_c('h1', {
    staticClass: "font-semibold text-grey-2"
  }, [_vm._v("Variant Attribute")]), _vm.isCreateNewClassVariants ? _c('div', {
    staticClass: "grid gap-2",
    class: ["".concat(_vm.classVariantSelected.attributeCombination.length > 1 ? 'grid-cols-2' : 'grid-cols-1')]
  }, _vm._l(_vm.classVariantSelected.attributeCombination, function (variantModal, variantModalIdx) {
    return _c('TextField', {
      key: 'variantModalIdx' + variantModalIdx,
      attrs: {
        "disabledWhite": "",
        "label": "".concat(_vm.classVariantSelected.attributeCombination.length > 1 ? "Attribute ".concat(variantModalIdx + 1) : 'Attribute'),
        "type": "text",
        "borderEnabled": ""
      },
      model: {
        value: _vm.classVariantSelected.attributeCombination[variantModalIdx],
        callback: function callback($$v) {
          _vm.$set(_vm.classVariantSelected.attributeCombination, variantModalIdx, $$v);
        },
        expression: "classVariantSelected.attributeCombination[variantModalIdx]"
      }
    });
  }), 1) : _c('div', {
    staticClass: "grid gap-2",
    class: ["".concat(_vm.classVariantSelected.attributeCombination.length > 1 ? 'grid-cols-2' : 'grid-cols-1')]
  }, _vm._l(_vm.classVariantSelected.attributeCombination, function (variantModal, variantModalIdx) {
    return _c('TextField', {
      key: 'variantModalIdx' + variantModalIdx,
      attrs: {
        "disabledWhite": "",
        "label": "".concat(_vm.classVariantSelected.attributeCombination.length > 1 ? "Attribute ".concat(variantModalIdx + 1) : 'Attribute'),
        "type": "text",
        "borderEnabled": ""
      },
      model: {
        value: _vm.classVariantSelected.attributeCombination[variantModalIdx].valueName,
        callback: function callback($$v) {
          _vm.$set(_vm.classVariantSelected.attributeCombination[variantModalIdx], "valueName", $$v);
        },
        expression: "classVariantSelected.attributeCombination[variantModalIdx].valueName"
      }
    });
  }), 1), _c('TextField', {
    attrs: {
      "label": "Variant Name",
      "type": "text",
      "borderEnabled": ""
    },
    model: {
      value: _vm.classVariantSelected.variantName,
      callback: function callback($$v) {
        _vm.$set(_vm.classVariantSelected, "variantName", $$v);
      },
      expression: "classVariantSelected.variantName"
    }
  }), _c('Dropdown', {
    attrs: {
      "label": "Status",
      "notes": "Only Active Variant will be shown on class detail page",
      "options": _vm.classVariantStatusOptions,
      "optionLabel": "name",
      "default": _vm.classVariantSelected.active
    },
    model: {
      value: _vm.classVariantSelected.active,
      callback: function callback($$v) {
        _vm.$set(_vm.classVariantSelected, "active", $$v);
      },
      expression: "classVariantSelected.active"
    }
  })], 1), _c('div', {
    staticClass: "border-b border-neutral-50 pb-4 mb-3 space-y-3"
  }, [_c('p', {
    staticClass: "font-semibold text-grey-2"
  }, [_vm._v("Pricing")]), _c('TextField', {
    attrs: {
      "label": "Price",
      "type": "number",
      "borderEnabled": ""
    },
    model: {
      value: _vm.classVariantSelected.price,
      callback: function callback($$v) {
        _vm.$set(_vm.classVariantSelected, "price", $$v);
      },
      expression: "classVariantSelected.price"
    }
  }), _c('div', {
    staticClass: "grid grid-cols-2 gap-2"
  }, [_c('TextField', {
    attrs: {
      "label": "Promo Label",
      "type": "text",
      "borderEnabled": "",
      "placeholder": "Enter promo label"
    },
    model: {
      value: _vm.classVariantSelected.promoLabel,
      callback: function callback($$v) {
        _vm.$set(_vm.classVariantSelected, "promoLabel", $$v);
      },
      expression: "classVariantSelected.promoLabel"
    }
  }), _c('TextField', {
    attrs: {
      "label": "Promo Price",
      "type": "number",
      "borderEnabled": ""
    },
    model: {
      value: _vm.classVariantSelected.promo,
      callback: function callback($$v) {
        _vm.$set(_vm.classVariantSelected, "promo", $$v);
      },
      expression: "classVariantSelected.promo"
    }
  })], 1)], 1), _c('h1', {
    staticClass: "font-semibold text-grey-2"
  }, [_vm._v("Web Content")]), _c('p', {
    staticClass: "text-xs text-gray-500"
  }, [_vm._v("If left empty, content from Bilingual menu will be used")]), _c('div', {
    staticClass: "py-3 mb-3 space-y-3"
  }, [_c('TextField', {
    attrs: {
      "label": "Duration (optional)",
      "type": "text",
      "placeholder": "Insert class duration",
      "borderEnabled": ""
    },
    model: {
      value: _vm.classVariantSelected.duration,
      callback: function callback($$v) {
        _vm.$set(_vm.classVariantSelected, "duration", $$v);
      },
      expression: "classVariantSelected.duration"
    }
  }), _c('TextField', {
    attrs: {
      "label": "Weekly Schedule (optional)",
      "type": "text",
      "placeholder": "Insert weekly schedule",
      "borderEnabled": ""
    },
    model: {
      value: _vm.classVariantSelected.weeklySchedule,
      callback: function callback($$v) {
        _vm.$set(_vm.classVariantSelected, "weeklySchedule", $$v);
      },
      expression: "classVariantSelected.weeklySchedule"
    }
  }), _c('TextField', {
    attrs: {
      "label": "Location (optional)",
      "type": "text",
      "placeholder": "Insert location",
      "borderEnabled": ""
    },
    model: {
      value: _vm.classVariantSelected.location,
      callback: function callback($$v) {
        _vm.$set(_vm.classVariantSelected, "location", $$v);
      },
      expression: "classVariantSelected.location"
    }
  })], 1), _c('div', {
    staticClass: "flex justify-end items-center gap-4 sticky bottom-0 bg-white pt-4 border-t border-neutral-250"
  }, [_c('Button', {
    attrs: {
      "buttonText": "Cancel",
      "type": "tertiary"
    },
    on: {
      "action": function action($event) {
        return _vm.toggleClassVariantModal();
      }
    }
  }), _c('Button', {
    attrs: {
      "buttonText": "Save",
      "type": "primary"
    },
    on: {
      "action": function action($event) {
        return _vm.toggleClassVariantModal();
      }
    }
  })], 1)])])], 2)], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "m-auto w-full"
  }, [_c('div', {
    staticClass: "font-semibold"
  }, [_vm._v("Error: Promo Price")]), _c('div', {
    staticClass: "font-medium text-xs"
  }, [_vm._v("Please remove Promo Price if Listing Price is not > Rp0")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex justify-center items-center"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/empty.svg")
    }
  })]);
}]

export { render, staticRenderFns }