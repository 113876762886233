import { mapActions } from 'vuex'

export default {
  name: 'upload-image-mixin',
  methods: {
    ...mapActions('common', ['uploadImageAsset', 'showLoading', 'hideLoading']),
    uploadImage(evt, item, field = '') {
      let imageData = new FormData()
      const fileName = evt.target.files[0].name.split('.')[0]
      imageData.append('file', evt.target.files[0])
      imageData.append('name', fileName)
      this.showLoading()
      this.uploadImageAsset({
        payloads: imageData
      }).then(res => {
        if (typeof item.url !== 'undefined' && field === '') {
          item.url = res.data.url
        } else {
          item[field] = res.data.url
        }
        this.hideLoading()
      })
    }
  }
}
